// import React from 'react'

// export default function ArticleMenuTemplate(props) {
//   return <div>{console.log(props)}</div>
// }

// ?===============================
// ?=====  3rd party Imports  =====
// ?===============================
import React, { useEffect, useContext } from 'react'
import { graphql } from 'gatsby'
import { Flex, useThemeUI } from 'theme-ui'
// import shortid from 'shortid'
import { TinyButton as ScrollUpButton } from 'react-scroll-up-button' //Add this line Here

// ?===================================
// ?===  Local Components Imports  ====
// ?===================================
import Layout from '../components/Layout/Layout'
import SEO from '../components/Layout/seo'
import { store } from '../Context/Store'

// ?===================================
// ?====  Local Function Imports  =====
// ?===================================
// import importPageComponents from '../helpers/importPageComponents'
import checkForCustomLinks from '../helpers/checkForCustomLinks'
import capitalizeFirstLetter from '../helpers/capitalizeFirstLetter'
import articleVariantSelector from '../components/Articles/DataSelectors/articleVariantSelector'

// ?===================================
// ?========  Style Imports  ==========
// ?===================================
import '../../src/index.css'
import 'react-image-lightbox/style.css'
import ContactFormsContainer from '../components/ContactForm/ContactFormsContainer'
// import ArticleMenu from '../components/Articles/ArticleMenu'
import ArticlePage from '../components/Articles/ArticlePage'

// This Template is used on every generated page on the site. apart from the multi location Landing page.
// its job is to get the data from the page context and graphql query and then funnel it to the right locations.
// data has to passed into two places. The Layout(i.e navigation and footer.) and the dyanmic component renderer
// this componenet also handle a dynamic theme color. passing the data into theme UI.

// ?===================================
// ?======  Template Component  =======
// ?===================================

const ArticlePageTemplate = (props) => {
  // ?===========================
  // ?===== Component State =====
  // ?===========================

  const { globalState, dispatch } = useContext(store)
  // const [components, setComponents] = useState(null) // where the pages componenets are stored after dynamically importing

  // ?==========================
  // ?====== Destructing  ======
  // ?==========================

  const { pageContext, data, location } = props
  const { businessData, poweredImages } = data
  const configData = data.allConfigData.edges[0].node
  // const allGeneral = data.allGeneral.edges.map(({ node }) => node) // removes node layer

  const { metaTitle, metaDescription } = configData
  const { name, desc, city, avatar, tags } = businessData

  // PAGE Context data that comes from gatsby node file gets desctructed from here
  const {
    // articleID,
    // gonationID,
    pageObjectKey,
    // locationIndex,
    // city,
    // pageComponents,
    customPageName,
    locations,
    isAdditionalPage,
  } = pageContext

  // ?==============================
  // ?=====  Variable Creation  ====
  // ?==============================

  // grabs the theme ui object for use
  const themeContext = useThemeUI().theme

  // this determines the page title used in the navigation.
  // if its custom page name use it. if not use the pageobject keywhich is the default name
  const pageTitle = `${customPageName !== '' ? customPageName : pageObjectKey}`
  const isMultiLocationSite = locations.length > 1

  // ?=============================================
  // ?==============   Functions   ================
  // ?=============================================

  // this gets the components for this page and dyanmically renders them and gives them all the props possible
  // so every componenet has access to any static data generated by the gatsy node files.
  // const getComponents = async () => {
  //   // the components props data is passed in here
  //   const data = await importPageComponents(pageComponents, {
  //     ...pageContext,
  //     businessData,
  //     configData,
  //     poweredImages,
  //     pageTitle,
  //     isMultiLocationSite,
  //   })
  //   // once the components have been import and the promise has been finished set the components to the state.
  //   // ready for rendering
  //   setComponents(data)
  // }

  // ?============================================
  // ?====== Dynamic Font Loading Operators ======
  // ?============================================

  const googleFamilies = themeContext.fonts.googleFonts
    ? themeContext.fonts.googleFonts
    : ['Roboto'] // without passing anything it breaks

  const customFamilies = themeContext.fonts.customFamilies
    ? themeContext.fonts.customFamilies
    : ['']
  const customUrls = themeContext.fonts.customUrls
    ? themeContext.fonts.customUrls
    : ['']

  // ?===================================================
  // ?==============   ON/OFF MOUNTING   ================
  // ?===================================================

  useEffect(() => {
    dispatch({
      type: 'SET_SITE_DATA',
      data: {
        ...pageContext,
        businessData,
        configData,
        poweredImages,
        // allGeneral,
        pageTitle,
        isMultiLocationSite,
        isAdditionalPage,
      },
    })

    // getComponents() // on load of the template/page get the components

    if (typeof window !== `undefined`) {
      const WebFont = require('webfontloader') // uses the webfont loadder library to dynamically load the right fonts
      WebFont.load({
        google: {
          families: googleFamilies,
        },
        custom: {
          families: customFamilies,
          urls: customUrls,
        },
      })
    }

    return () => {}
  }, [])

  const articleDataSelector = (pageObjectKey) => {
    switch (pageObjectKey) {
      case 'team':
        return 'team'

      case 'press':
        return 'press'

      case 'general':
        return 'general'

      case 'policies':
        return 'policies'

      case 'blog':
        return 'blog'

      default:
        break
    }
  }

  const allArticleDataSelector = (pageObjectKey) => {
    switch (pageObjectKey) {
      case 'blog':
        return 'allBlog'

      case 'team':
        return 'allTeam'

      case 'press':
        return 'allPress'

      case 'general':
        return 'allGeneral'

      case 'policies':
        return 'allPolicies'

      default:
        break
    }
  }

  // ?=========================
  // ?===== JSX rendering =====
  // ?=========================

  return (
    <Layout
      {...pageContext}
      navLinks={checkForCustomLinks(configData)}
      businessData={businessData}
      configData={configData}
      isMultiLocationSite={locations.length > 1}
      location={location}
    >
      <SEO
        title={
          metaTitle
            ? `${metaTitle} | ${capitalizeFirstLetter(pageTitle)} | ${city}`
            : `${name} | ${capitalizeFirstLetter(pageTitle)} | ${city}`
        }
        description={metaDescription ? `${metaDescription}` : `${desc}`}
        keywords={tags ? `${tags}` : `${[]}`}
        favicon={avatar.imageBaseUrl + '/' + avatar.imagePrefix}
      />

      <Flex
        sx={{ flexDirection: 'column' }}
        className='pageContainer articlePageContainer'
      >
        {props.data[articleDataSelector(pageObjectKey)] ? (
          <ArticlePage
            articleData={data[articleDataSelector(pageObjectKey)]}
            pageObjectKey={pageObjectKey}
            variantName={articleVariantSelector(pageObjectKey, 'page')}
            // allArticlesData={props.data[allArticleDataSelector(pageObjectKey)].edges.map(
            //   ({ node }) => node
            // )} // strip and destructure back the layers of objects(removing edges and node) and have just had one level of nested objects
          />
        ) : (
          'Article Missing'
        )}
        {/* // *The Page Contiainer Element Allows Us to Rearrange the contact form with flexOrder */}
        {/* {components
          ? components.map(Component => {
              // * components are dyanmnically imported and rendered Here
              return <Component key={shortid.generate()} />
            })
          : ''} */}
        {/*
        // * A Contact Form Has to Be Statically imported and Cannot By Dyanmically
        // *as netlify will not pick up the forms - To add Additional Forms Shoadow ContactFormsContainer
        */}
        <ContactFormsContainer
          {...pageContext}
          businessData={businessData}
          configData={configData}
          pageTitle={pageTitle}
          isMultiLocationSite={isMultiLocationSite}
          variantName={configData.contactForm.variantName}
          options={configData.contactForm.options}
        />
      </Flex>

      <ScrollUpButton className='scrollUpButton' style={scrollUpButtonStyles} />
    </Layout>
  )
}

export default ArticlePageTemplate

// ?=====================================
// ?======= GraphQl page queries ========
// ?=====================================

export const query = graphql`
  query articlePageTemplateQuery($city: String, $articleID: String!) {
    businessData(city: { eq: $city }) {
      ...businessDataFields
    }

    allConfigData {
      edges {
        node {
          ...allConfigDataFields
        }
      }
    }

    poweredImages {
      ...poweredImagesData
    }

    allBlog(sort: { order: DESC, fields: createdAt }) {
      edges {
        node {
          ...blogArticleData
        }
      }
    }

    allTeam {
      edges {
        node {
          ...teamArticleData
        }
      }
    }

    allPress {
      edges {
        node {
          ...pressArticleData
        }
      }
    }

    blog(id: { eq: $articleID }) {
      ...blogArticleData
    }
    team(id: { eq: $articleID }) {
      ...teamArticleData
    }
    press(id: { eq: $articleID }) {
      ...pressArticleData
    }
    general(id: { eq: $articleID }) {
      ...generalArticleData
    }
    policies(id: { eq: $articleID }) {
      ...policiesArticleData
    }
  }
`

const scrollUpButtonStyles = {
  bottom: '0.5rem',
  left: '0.5rem',
  zIndex: '500',
  backgroundColor: 'rgb(255 255 255 / 45%)',
}
