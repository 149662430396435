import React from 'react';
import { Box, Heading, Image, Text, Link } from 'theme-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Tags from './ArticleComponents/Tags';
import OtherArticles from './ArticleComponents/OtherArticles';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function ArticlePage({ articleData, pageObjectKey, variantName, allArticlesData }) {
  const variant = variantName ? variantName : `${pageObjectKey}Page`;

  const articleInfo = () => (
    <>
      {articleData.author && (
        <Text as="p" className="author" variant={`${variant}.author`}>
          By {articleData.author}
        </Text>
      )}

      <Box variant={`${variant}.timeContainer`} className="timeContainer">
        <Text variant={`${variant}.readingTime`} className="readingTime">
          <FontAwesomeIcon icon={faClock} /> {articleData.readingTimeText}
        </Text>
        <Text variant={`${variant}.createdAt`} className="createdAt">
          <FontAwesomeIcon icon={faCalendarAlt} />{' '}
          {moment(articleData.createdAt).format('DD MMM, YYYY')}
        </Text>
      </Box>
    </>
  );

  const OtherArticlesTitle = () => {
    switch (pageObjectKey) {
      case 'blog':
        return 'Other Articles';

      case 'team':
        return 'Other Team Members';

      case 'press':
        return 'In The Press';

      default:
        break;
    }
  };

  return (
    <Box
      sx={{ variant: `customVariants.${pageObjectKey}Page` }}
      variant={`${variant}.container`}
      className="container"
    >
      <Box variant={`${variant}.imageContainer`} className="imageContainer">
        <Slider {...settings}>
          {articleData?.media.map(image => {
            return (
              <Image
                variant={`${variant}.image`}
                src={`https://res.cloudinary.com/gonation/${image.cloudinaryId}`}
              />
            );
          })}
        </Slider>
      </Box>

      <Box variant={`${variant}.contentRow`} className="contentRow">
        <Box variant={`${variant}.column1`} className="column1">
          <Box variant={`${variant}.heading`} className="heading">
            <Heading as="h3" className="title" variant={`${variant}.title`}>
              {articleData.title}
            </Heading>
            <Heading as="h4" className="subtitle" variant={`${variant}.subtitle`}>
              {articleData.subtitle}
            </Heading>
            {articleData.tags && <Tags tags={articleData.tags} variant={variant} />}
            {pageObjectKey !== 'team' && (
              <Box variant={`${variant}.articleInfoMobile`} className="articleInfoMobile">
                {articleInfo()}
              </Box>
            )}
          </Box>

          <Box
            variant={`${variant}.bodyContainer`}
            dangerouslySetInnerHTML={{ __html: articleData.bodyHTML }}
            className="bodyContainer"
          ></Box>
          {articleData?.ctaLink?.url && articleData?.ctaLink?.title ? (
            <Link
              variant={`${variant}.ctaButton`}
              className="ctaButton"
              href={articleData?.ctaLink?.url}
              target="_blank"
            >
              {articleData?.ctaLink?.title}
            </Link>
          ) : (
            ''
          )}
        </Box>

        <Box variant={`${variant}.column2`} className="column2">
          {pageObjectKey !== 'team' && (
            <Box variant={`${variant}.articleInfoDesktop`} className="articleInfoDesktop">
              {articleInfo()}
            </Box>
          )}
          <Box>
            {allArticlesData?.length > 1 && (
              <OtherArticles
                allArticlesData={allArticlesData}
                currentArticleId={articleData.id}
                variant={variant}
                pageObjectKey={pageObjectKey}
                title={OtherArticlesTitle()}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

var settings = {
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 4000,
  interval: 4000,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: false,
};
