import React from 'react'
import { Box, Text } from 'theme-ui'

export default function Tags({ tags, variant }) {
  return (
    <>
      {tags && (
        <Box variant={`${variant}.tagsContainer`} className='tagsContainer'>
          {tags.map((tag) => (
            <Text variant={`${variant}.tagPill`} className='tagPill'>
              {tag}
            </Text>
          ))}
        </Box>
      )}
    </>
  )
}
